/* Jobmorgen styles */

@import "../colors";
@import "../settings";

.kmta__tenant--jobmorgen {
    .kmta__headerBottom {
        padding: 1px 0 3px;
        margin-top: 10px;
    }

    a.jobmorgen__logo {
        margin-top: 10px;

        .jobmorgen_svgIcon--logo {
            width: 308px;
            height: 52px;
            box-shadow: 0px 0px 3px var(--gray80);
        }
    }

    .kmta__faqHome {
        background: linear-gradient(
            to bottom,
            rgba(236, 237, 237, 1) 0%,
            rgba(198, 199, 200, 1) 100%
        );
    }

    .dropdown.menu .is-active > a,
    .kmta__menu li.is-submenu-item a:hover {
        color: var(--nav-item-hover-color);
    }

    .dropdown.menu li.active > a,
    .menu.is-dropdown-submenu
    li.is-submenu-item.is-dropdown-submenu-item.active
    > a {
        font-weight: $global-weight-bold;
    }

    @include breakpoint(small down) {
        .kmta__headerTop {
            padding: 0.4rem;
        }
        a.jobmorgen__logo .jobmorgen_svgIcon--logo {
            width: 75%;
            height: auto;
        }
    }
}
